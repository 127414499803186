<template>
  <div class="container">
	<!-- 设置默认交易所 -->
	<van-nav-bar
	    :title="$t('system.defaultExchange')"
	    left-arrow
	    @click-left="onClickLeft"
	    safe-area-inset-top
	    fixed
	    z-index="9999"
	/>
	<div class="content g_safe_top">
		<div class="layoutBox bgWhite">
			<van-radio-group v-model="exchange">
				<div v-for="(item,index) in apiDataList" :key="index"
					class="list-radio-item" 
					@click="exchange = item.name_py">
					<div class="label">
						<div class="icon"><img :class="{hasBg:item.name_py=='okex'||item.name_py=='bitrocket'}" :src="require('@/assets/imgs/new_color/api-'+item.name_py+'.png')" alt=""></div>
						{{
							item.name_py=='huobi'?$t('system.huobi'):
							item.name_py=='bian'?$t('system.bian'):
							item.name_py=='okex'?$t('system.OKEx'):
							item.name_py=='bitrocket'?$t('system.bitrocket'):
							item.name_py.toUpperCase()
						}}
					</div>
					<van-radio :name="item.name_py">
						<template #icon="props">
						  <img
						    class="img-icon"
						    style="width: 0.32rem;display: block;"
						    :src="
						      require('@/assets/imgs/new_color/' +
						        (props.checked ? 'icon_choose' : 'icon_unchoose') +
						        '.png')
						    "
						  />
						</template>
					</van-radio>
				</div>
			</van-radio-group>
			
			<div class="comBtnStyle" @click="setObject">{{ $t('system.save') }}</div>
		</div>
	</div>
	
  </div>
</template>

<script>

export default {
  name: "defaultExchange",
  data() {
    return {
		phone: "", // 手机号
		sms: "", // 短信验证码
		flag: false, // 获取验证码 是否禁用
		count: 60, // 60s 倒计时
		timer: null, // 倒计时的 定时器
		sid: "", // 验证
		bindPhone: false,
		openid: "",
		email: "",
		vcode_method: "sms",
		exchange: "",

		apiDataList: [
			{id: 4, name_py: 'bitrocket'},
			{id: 3, name_py: 'okex'},
			{id: 2, name_py: 'bian'},
			{id: 1, name_py: 'huobi'},
		],
    };
  },
  created() {
    this.initData();

    // this.$dialog.alert({
    //     message: this.openid + ":" + this.bindPhone
    // });

	// this.getAPIDataList();
  },
  methods: {
    initData() {
      this.$post2("user/api/index/getMetaInfo", {})
        .then((res) => {
          this.email = res.email;
          this.phone = res.phone;
          this.exchange = res.exchange;
          this.vcode_method = res.vcode_method;
        })
        .catch((e) => {
          this.$toast.fail(e);
        });
    },
	// getAPIDataList() {
	// 	this.$post2("Trade/Api/Index/getApiSet").then((res) => {
	// 		this.apiDataList = res.list;
	// 	});
	// },
    // onClickLeft  点击返回“设置”页面
    onClickLeft() {
      this.$router.back(-1);
    },

    // 设置默认交易所
    setObject() {
      this.$post2("user/api/index/setDefaultExchange", {
        exchange: this.exchange,
      })
        .then((res) => {
          this.$toast.success(this.$t("system.savesucc"));
          //  this.$router.go(-1);
          var userInfo = JSON.parse(localStorage.userInfo);
          userInfo['default_exchange'] = this.exchange;
          localStorage.userInfo = JSON.stringify(userInfo);
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
  },
};
</script>

<style scoped lang="less">
.backgrondRoade {
  width: 0.6rem;
  background: black;
  height: 0.6rem;
}

.duihaoImg {
  width: 0.3rem;
  margin-left: 5.6rem;
}

/* @import "../../assets/css/puliceStyle.css"; */
.body1StyleInputStyle {
  border: 0px;
}

.bodyStyleDivStyleP {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.bodyStyleDivStyle {
  border-bottom: 1px solid #fbfbfd;
  padding-bottom: 0.3rem;
}

.body1Style {
  padding: 0.3rem;
}


.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}

.bottomBtnStyle {
  width: 100%;
  background: #e1c364;
  border-radius: 5px;
  border: 0px;
}

.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}

.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}

/* ---------20200411[start]------- */
.container,
.content{
	height: 100%;
}
.layoutBox {
	min-height: calc(100% - 0.2rem);
	// height: 100vh;
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	padding: 0 0.2rem 0.4rem;
	font-family: PingFangSC-Regular;
}

.list-radio-item {
	padding: .2rem 0;
	margin: 0 0.2rem;
	height: 1.2rem;
	border-bottom: 1px solid #EDEDED;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.label {
		color: #333333;
		font-size: .28rem;
		display: flex;
		align-items: center;
	}
	.icon {
		width: .7rem;
		height: .7rem;
		margin-right: .15rem;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			display: block;
			width: 100%;
		}
		.hasBg{
			width: 70%;
		}
	}
}
.comBtnStyle {
	height: 0.8rem;
	line-height: 0.8rem;
	margin: 6rem 0.2rem 0;
}
/* ---------20200411[end]------- */
</style>
